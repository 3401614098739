import React, { useState } from 'react';
import './Navbar.css';
import logo from '../image/MeV-Alt.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/"><img className="img-logo" src={logo} alt="Muraro & Vaz" /></a>
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <button onClick={() => scrollToSection("CardHolder")}>Serviços</button>
        <button onClick={() => scrollToSection("About")}>Contato</button>
      </div>
      <div className="hamburger" onClick={toggleNavbar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
