import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './assets/components/navbar';
import Homepage from './assets/pages/homepage.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Homepage />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
