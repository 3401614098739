import React from "react";
import './About.css';
import ContactForm from "./formHandler";
const About = () => {
    return (
        <div className="AboutContainer" id="About">
            <div className="AboutText">
            <h2>Nossa Equipe</h2>
            <h3>Somos uma equipe dedicada e multidisciplinar, unindo experiências em marketing e tecnologia para entregar soluções inovadoras e personalizadas. Nosso time é composto por dois profissionais comprometidos com o desenvolvimento de estratégias eficientes e sustentadas pelo uso inteligente de dados.<br/>
            Juntos, trabalhamos para unir estratégias de marketing e inteligência de dados, oferecendo soluções que ajudam nossos clientes a alcançar seus objetivos de maneira assertiva e sustentável. Nossa missão é não só atender às expectativas, mas superá-las, criando valor para cada projeto.</h3>
            </div>
            <ContactForm/>
        </div>
    )
};

export default About;