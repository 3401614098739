import React from 'react';
import HeroCard from '../components/hero.js';
import CardGridWithOverrides from '../components/card.js';
import About from '../components/about.js';
import '../components/Scrollbar.css'

function HomePage() {
    return (
        <div className='HomePage'>
            <HeroCard />
            <CardGridWithOverrides />
            <About />
        </div>
    );
}

export default HomePage;
