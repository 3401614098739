import iconStrat from "../image/Icon/1.svg";
import iconGerenc from "../image/Icon/2.svg";
import iconAnalitic from "../image/Icon/3.svg";
import iconDesign from "../image/Icon/4.svg";
import iconCatalog from "../image/Icon/5.svg";
import iconDev from "../image/Icon/6.svg";
const CardData = [
    {icon: iconStrat, title: "Estratégia", description: "Criamos conteúdos estratégicos, alinhados aos objetivos da sua marca, para engajar o público e fortalecer sua presença digital com consistência e relevância.", longdescription: "Desenvolver uma estratégia sólida e eficaz é o primeiro passo para alcançar o sucesso nas plataformas digitais. Nossa equipe cria conteúdos cuidadosamente planejados e estrategicamente alinhados com os objetivos de longo prazo da sua marca. Não se trata apenas de aumentar o engajamento, mas de construir uma presença digital consistente e relevante, capaz de atrair e fidelizar um público qualificado. Através de pesquisas aprofundadas sobre seu público-alvo, tendências de mercado e análise de concorrentes, implementamos táticas que engajam, educam e inspiram, criando um relacionamento duradouro entre sua marca e os consumidores. Cada campanha é projetada para gerar resultados tangíveis, ampliando a visibilidade da sua marca e fortalecendo sua identidade online de forma eficaz."}, 
    {icon: iconGerenc, title: "Gerenciamento de perfil", description: "Desenvolvemos uma estratégia personalizada para gerenciar seu perfil, conectando sua marca ao público certo e fortalecendo sua presença digital de forma autêntica e eficaz.", longdescription: "O gerenciamento de perfil vai além de simples postagens – é sobre criar uma conexão genuína com o seu público e garantir que sua marca se destaque de maneira autêntica em um ambiente digital saturado. Oferecemos um serviço completo de gerenciamento, desde a criação de conteúdo até a análise de interações, com o objetivo de aumentar a visibilidade da sua marca e estreitar os laços com seus seguidores. Nossa estratégia inclui a definição de um tom de voz único e alinhado à personalidade da sua marca, o planejamento de conteúdo coerente com os valores da empresa e a otimização de posts para alcançar o público certo. Além disso, monitoramos constantemente a performance do perfil, ajustando a abordagem conforme necessário para garantir que sua marca se mantenha relevante e eficaz na atração de novos seguidores, mantendo também a fidelização dos já existentes."},
    {icon: iconAnalitic, title: "Analise de perfil", description: "Realizamos uma análise completa do seu perfil, otimizando username, bio, foto, destaques e estrutura do feed para potencializar sua identidade digital.", longdescription: "A análise de perfil é uma avaliação detalhada de todos os aspectos que compõem a identidade digital da sua marca. Isso inclui uma revisão crítica do seu nome de usuário, biografia, imagem de perfil, destaques e feed, com o objetivo de garantir que cada elemento esteja otimizado para o público que você deseja alcançar. Levamos em consideração as melhores práticas de SEO, as tendências de design e o comportamento do usuário para ajustar e aprimorar todos os pontos de contato da sua presença online. Além disso, com base nos dados coletados, fornecemos recomendações personalizadas para melhorar a coerência visual, a clareza das mensagens e a eficácia geral do perfil, resultando em uma experiência digital mais envolvente e impactante para seus seguidores."},
    {icon: iconDesign, title: "Design Gráfico", description: "Produzimos logotipos e materiais visuais personalizados que refletem a essência da sua marca.", longdescription: "O design gráfico que oferecemos é mais do que uma simples criação visual – é uma ferramenta poderosa para comunicar os valores e a essência da sua marca de forma impactante e memorável. Desenvolvemos logotipos exclusivos que capturam a identidade visual de sua empresa, criando uma imagem marcante que transmite confiança e profissionalismo. Além disso, produzimos materiais gráficos personalizados, como cartões de visita, banners e apresentações, sempre com atenção aos detalhes e à consistência estética. O design gráfico é essencial para transmitir a mensagem certa para o seu público-alvo, seja nas redes sociais, no site ou em materiais impressos. Nossa missão é criar designs que se destaquem no mercado, fortalecendo a percepção positiva da sua marca e ajudando-a a se destacar da concorrência."},
    {icon: iconCatalog, title: "Artes para Publicidade e Catálogos", description: "Desenvolvemos artes exclusivas para anúncios e catálogos, garantindo destaque e atraindo a atenção do público-alvo.", longdescription: "Criar artes para publicidade e catálogos exige uma abordagem estratégica e criativa para garantir que sua marca se destaque no mercado e atraia a atenção de seus consumidores ideais. Nossa equipe de designers trabalha para desenvolver peças publicitárias visualmente atraentes e eficazes, que destacam os atributos e benefícios dos seus produtos ou serviços. Seja para anúncios em redes sociais, banners digitais ou materiais impressos como catálogos, garantimos que cada arte seja projetada para gerar o máximo de impacto, com foco no público-alvo e nas necessidades de comunicação da sua marca. Nós entendemos a importância de uma arte bem-feita não apenas como uma ferramenta de promoção, mas também como um reflexo da identidade da sua marca, garantindo que cada peça de publicidade seja coesa e impactante."},
    {icon: iconDev, title: "Desenvolvimento de Sites", description: "Criamos sites para portfólio e vendas, projetados para fortalecer sua presença online e impulsionar seu negócio.", longdescription: "O desenvolvimento de sites é uma das maneiras mais eficazes de solidificar sua presença online e fornecer uma experiência fluida e envolvente para seus clientes. Criamos sites totalmente personalizados, desde páginas de portfólio visualmente impactantes até e-commerce funcional e sites institucionais, projetados para destacar sua marca e maximizar a conversão. Cada site que desenvolvemos é responsivo, adaptando-se perfeitamente a dispositivos móveis, e otimizado para um desempenho rápido e eficiente. Trabalhamos com você para entender as necessidades e objetivos do seu negócio, criando soluções digitais que não só atendem às expectativas dos usuários, mas também impulsionam os resultados comerciais. Seja para aumentar as vendas, melhorar a visibilidade online ou criar uma plataforma de interação com seu público, garantimos que o design e a funcionalidade do seu site estejam alinhados com sua identidade digital e com os objetivos de crescimento da sua marca."}
];

export default CardData;
