import React, { useState } from "react";
import CardData from "./cardData";
import './Card.css';
import CardAlt from '../components/cardAlt.js';

const Card = ({ icon, title, description, longdescription, onClick, isSelected }) => {
    return (
        <div className={`Card ${isSelected ? "SelectedCard" : ""}`} onClick={onClick}>
            {isSelected ? (
                <>
                    <h1>{title}</h1>
                    <h3>{longdescription}</h3>
                    <button onClick={onClick} className="CloseButton">Close</button>
                </>
            ) : (
                <>
                    <div className="Icon">
                        <img src={icon} alt={title} />
                    </div>
                    <div className="TextBox">
                        <h3>{title}</h3>
                        <p>{description}</p>
                    </div>
                </>
            )}
        </div>
    );
};

const CardGridWithOverrides = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleCardClick = (index) => {
        if (!isAnimating) {
            setIsAnimating(true);
            setTimeout(() => {
                setSelectedIndex(index === selectedIndex ? null : index);
                setIsAnimating(false);
            }, 500);
        }
    };

    const handleCloseButtonClick = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            setTimeout(() => {
                setSelectedIndex(null);
                setIsAnimating(false);
            }, 500);
        }
    };

    return (
        <div className={`CardHolder ${isAnimating ? (selectedIndex === null ? "animate-out" : "animate-in") : ""}`} id="CardHolder">
            {selectedIndex === null ? (
                <>
                    <CardAlt />
                    <div className="Card_Container">
                        {CardData.map((cardItem, index) => (
                            <Card
                                key={index}
                                icon={cardItem.icon}
                                title={cardItem.title}
                                description={cardItem.description}
                                onClick={() => handleCardClick(index)}
                                isSelected={false}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className="SelectedCardView">
                    <h1>{CardData[selectedIndex].title}</h1>
                    <h3>{CardData[selectedIndex].longdescription}</h3>
                    <button onClick={handleCloseButtonClick} className="CloseButton">
                        Retornar
                    </button>
                </div>
            )}
        </div>
    );
};

export default CardGridWithOverrides;
