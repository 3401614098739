import React from "react";
import './Hero.css'
import logo from '../image/MeV.png'
// import bgHero from '../image/HeroBg.jpg'

const HeroCard = () =>{
    return(
        <section className="Hero_Container">
            <div className="Hero_Div">
                <div className="Hero_Holder">
                    <img className="Hero_Logo"src={logo} alt="logo"></img>
                    <h1>Quer fortalecer sua marca nas redes sociais?</h1><h2> Oferecemos <b>soluções completas</b> para transformar a presença digital da sua marca, unindo estratégia, gestão e design com foco em <b>resultados</b>. Desenvolvemos conteúdos estratégicos alinhados aos seus objetivos, criando um impacto real no engajamento do público e fortalecendo a relevância da sua marca nas redes sociais.</h2>
                </div>
            </div>
        </section>
    );
};

export default HeroCard