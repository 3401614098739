import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import './FormHandler.css';

function ContactForm() {
  const [state, handleSubmit] = useForm("xvgobjea");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const RECAPTCHA_SITE_KEY = "6LdE_IsqAAAAAFTuhxEe3DkSEiNSwUlBHL1g1co7"; // Your reCAPTCHA site key

  // Function to handle phone input formatting
  const formatPhone = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters
    const formatted = cleaned.replace(/^(\d{2})(\d{5})(\d{0,4}).*/, "$1-$2-$3"); // Format as XX-XXXXX-XXXX
    return formatted;
  };

  // Email validation function
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle phone input change
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhone(e.target.value);
    setPhone(formattedPhone);
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Get reCAPTCHA token
  const getRecaptchaToken = async () => {
    try {
      const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action: "submit" });
      setRecaptchaToken(token); // Set the token
    } catch (error) {
      console.error("reCAPTCHA error:", error);
    }
  };

  // Run reCAPTCHA when the component mounts
  useEffect(() => {
    getRecaptchaToken();
  }, []);

  // Form submission logic with reCAPTCHA token
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (recaptchaToken) {
      // Pass the reCAPTCHA token along with the form data
      handleSubmit(e);
    } else {
      alert("Por favor, complete o reCAPTCHA.");
    }
  };

  if (state.succeeded) {
    return <p>Obrigado! Retornaremos o contato em breve.</p>;
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <label htmlFor="name">Nome</label>
      <input id="name" type="text" name="name" required />
      <ValidationError prefix="Name" field="name" errors={state.errors} />

      <label htmlFor="phone">Telefone</label>
      <input
        id="phone"
        type="tel"
        name="phone"
        value={phone}
        onChange={handlePhoneChange}
        required
        pattern="[0-9]{2}-[0-9]{5}-[0-9]{4}"
      />
      <ValidationError prefix="Phone" field="phone" errors={state.errors} />
      {!phone.match(/\d{2}-\d{5}-\d{4}/) && phone && (
        <p style={{ color: "#ebc350" }}>
          Por favor, insira um telefone no formato correto (XX-XXXXX-XXXX).
        </p>
      )}

      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        name="email"
        value={email}
        onChange={handleEmailChange}
        required
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      {!validateEmail(email) && email && (
        <p style={{ color: "#ebc350" }}>
          Por favor, insira um endereço de email válido.
        </p>
      )}

      <label htmlFor="message">Mensagem</label>
      <textarea id="message" name="message" required />
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      {/* Invisible reCAPTCHA */}
      <button
        type="submit"
        className="g-recaptcha"
        data-sitekey={RECAPTCHA_SITE_KEY}
        data-callback="onSubmit"
        data-action="submit"
        disabled={state.submitting || !recaptchaToken}
      >
        Enviar
      </button>
    </form>
  );
}

export default ContactForm;
