import React from "react";
import './CardAlt.css';

const CardAlt = () => {
    return (
        <div className="CardAltContainer">
            <h2><b>Impulsione seu negócio</b></h2>
            <h3>Ajudamos sua marca a se destacar no mundo digital! Criamos conteúdos estratégicos, alinhados aos seus objetivos, e cuidamos da gestão do seu perfil com uma abordagem personalizada. Também analisamos e otimizamos cada detalhe para fortalecer sua identidade online. Para completar, desenvolvemos designs exclusivos, como logotipos, artes, catálogos e sites, conectando sua marca ao público certo com autenticidade e impacto.</h3>
        </div>
    )
};

export default CardAlt;